import React, { useEffect, useState } from "react";
import axios from "axios";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import SearchField from "../logs/components/SearchField";
import CredentialsTable from "./components/CredentialsTable";
import OperationSystemTab from "../EndpointsGroups/components/OperationSystemTab";
import CreateCredentialDialog from "./components/CreateCredentialDialog";
import { Stack } from "@mui/material";
import { myLocalStorage } from "../../../components/StorageHelper";
import { NGROK } from "../../../APIs";
import TenantSelection from "../Components/TenantSelection";

const Credentials = () => {
  const storageOS = myLocalStorage.getItem("selectedOS") || "WINDOWS";
  const tenant = myLocalStorage.getItem("latestTenant");

  const [osValue, setOsValue] = useState(storageOS);
  const [credentialsList, setCredentialsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const fetchCredentials = async () => {
    try {
      const res = await axios.get(`${NGROK}/api/credentials`);

      setCredentialsList(res.data?.content);
    } catch (error) {
      setCredentialsList([]);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCredentials();
  }, []);

  const { searchTerm, filteredData, handleSearch } =
    useGeneralSearch(credentialsList);

  const credentialLables = credentialsList?.map((cred) => cred.label.split("_")[2].toLowerCase());

  return (
    <Stack spacing={2}>
      {" "}
      <Stack direction={"row"} spacing={2}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        <CreateCredentialDialog
          osValue={osValue}
          tenantName={selectedTenant?.tenantName}
          setCredentialsList={setCredentialsList}
          credentialLables={credentialLables}
        />
      </Stack>
      <OperationSystemTab
        content={
          <CredentialsTable
            osValue={osValue}
            credentials={filteredData}
            tenantName={selectedTenant?.tenantName}
            fetchCredentials={fetchCredentials}
            setCredentialsList={setCredentialsList}
          />
        }
        osValue={osValue}
        setOsValue={setOsValue}
      />
    </Stack>
  );
};

export default Credentials;
