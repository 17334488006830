import React from "react";
import SignUpForm from "./components/SignUpForm";
import { Box, Container, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#233141",
    },
  },
});

const CustomSignUp = () => {

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            <SignUpForm />
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default CustomSignUp;
