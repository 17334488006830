import React, { useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormLogo from "./components/FormLogo";
import SignInEmailCheck from "./components/SignInEmailCheck";
import SignInForm from "./components/SignInForm";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#233141",
    },
  },
});

const CustomSignIn = () => {
  const [activeForm, setActiveForm] = useState({
    isEmailActive: true,
    isSignInActive: false,
  });
  const [tenantName, setTenantName] = useState("");
  const [realmName, setRealmName] = useState("");

  const [email, setEmail] = useState("");

  const setEmailFormActive = () => {
    setActiveForm({
      isEmailActive: true,
      isSignInActive: false,
    });
  };

  const setPasswordFormActive = () => {
    setActiveForm({
      isEmailActive: false,
      isSignInActive: true,
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <FormLogo />
          {activeForm.isEmailActive ? (
            <SignInEmailCheck
              togleActiveForm={setPasswordFormActive}
              setRealmName={setRealmName}
              setTenantName={setTenantName}
              email={email}
              setEmail={setEmail}
            />
          ) : null}
          {activeForm.isSignInActive ? (
            <SignInForm
              togleActiveForm={setEmailFormActive}
              email={email}
              tenantName={tenantName}
              realmName={realmName}
              setRealmName={setRealmName}
            />
          ) : null}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default CustomSignIn;
